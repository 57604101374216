import React, { useState } from 'react';
import './Login.css';
import { FcGoogle } from 'react-icons/fc'; // Import Google icon from react-icons
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate from react-router-dom for navigation
import axios from 'axios'; // Import axios for API requests
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating tokens

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // For redirecting after login

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      email: email,
      password: password
    };

    try {
      const response = await axios.post('https://crowdbuzz.in/api/loginUser.php', userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.status === 'success') {
        toast.success('Login successful!');
        // You can store user data in local storage if needed
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate('/'); // Redirect to homepage after successful login
      } else {
        toast.error(response.data.message); // Show error message from API
      }
    } catch (error) {
      console.error('Error during login:', error.response);
      toast.error('An error occurred during login.');
    }
  };

  const handleGoogleSignIn = () => {
    // Handle Google sign-in (implementation is not provided here)
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Enter your email"
              value={email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="Enter your password"
              value={password}
              onChange={handleInputChange}
              required
            />
            <a href="#" className="forgot-password">Forgot Password?</a>
          </div>
          <button type="submit" className="btn btn-primary btn-block">Login</button>
        </form>
        <hr />
        <button className="btn btn-google btn-block" onClick={handleGoogleSignIn}>
          <FcGoogle size={24} /> Continue with Google
        </button>
        <div className="signup-link">
          <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
        </div>
      </div>
      <ToastContainer autoClose={4000} position="top-center" />
    </div>
  );
}

export default Login;
